<template>
<link href="https://fonts.googleapis.com/css?family=Nunito:200,300,400,500,600,700,800,900&display=swap" rel="stylesheet">
<link rel="stylesheet" href="https://rsms.me/inter/inter.css">
<transition name="fade" appear>
   <div
      class="flex z-20 flex-wrap h-screen fixed w-screen bg-gray-700 top-0 left-0 content-center justify-center bg-opacity-75"
      v-if="store.isLoading"
    >
      <LoaderComponent />
    </div>
  </transition>

    <div :v-if="isHidden" style="min-width: 400px">
  <div class="bg-gray-100 min-h-screen">
    <div :class="{hidden: isHidden}">
      <!-- Mobile menu -->
      <Suspense>
      <NavBar />
    </Suspense>
    </div>

    <div>
      <!-- Mobile filter dialog -->
      <TransitionRoot as="template" :show="mobileFiltersOpen">
        <Dialog as="div" class="relative z-40 lg:hidden" @close="mobileFiltersOpen = false">
          <TransitionChild as="template" enter="transition-opacity ease-linear duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="transition-opacity ease-linear duration-300" leave-from="opacity-100" leave-to="opacity-0">
            <div class="fixed inset-0 bg-black bg-opacity-25" />
          </TransitionChild>

          <div class="fixed inset-0 z-40 flex">
            <TransitionChild as="template" enter="transition ease-in-out duration-300 transform" enter-from="translate-x-full" enter-to="translate-x-0" leave="transition ease-in-out duration-300 transform" leave-from="translate-x-0" leave-to="translate-x-full">
              <DialogPanel class="relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-white py-4 pb-6 shadow-xl">
                <div class="flex items-center justify-between px-4">
                  <h2 class="text-lg font-medium text-gray-900">Filters</h2>
                  <button type="button" class="-mr-2 flex h-10 w-10 items-center justify-center p-2 text-gray-400 hover:text-gray-500" @click="mobileFiltersOpen = false">
                    <span class="sr-only">Close menu</span>
                    <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>

                <!-- Filters -->
                <form class="mt-4">
                  <Disclosure as="div" v-for="section in filters" :key="section.name" class="border-t border-gray-200 pt-4 pb-4" v-slot="{ open }">
                    <fieldset>
                      <legend class="w-full px-2">
                        <DisclosureButton class="flex w-full items-center justify-between p-2 text-gray-400 hover:text-gray-500">
                          <span class="text-sm font-medium text-gray-900">{{ section.name }}</span>
                          <span class="ml-6 flex h-7 items-center">
                            <ChevronDownIcon :class="[open ? '-rotate-180' : 'rotate-0', 'h-5 w-5 transform']" aria-hidden="true" />
                          </span>
                        </DisclosureButton>
                      </legend>
                      <DisclosurePanel class="px-4 pt-4 pb-2">
                        <div class="space-y-6">
                          <div v-for="(option, optionIdx) in section.options" :key="option.value" class="flex items-center">
                            <input :id="`${section.id}-${optionIdx}-mobile`" :name="`${section.id}[]`" :value="option.value" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500" />
                            <label :for="`${section.id}-${optionIdx}-mobile`" class="ml-3 text-sm text-gray-500">{{ option.label }}</label>
                          </div>
                        </div>
                      </DisclosurePanel>
                    </fieldset>
                  </Disclosure>
                </form>
              </DialogPanel>
            </TransitionChild>
          </div>
        </Dialog>
      </TransitionRoot>
  

      <main class="mx-auto 	px-4 lg:px-8">

        <router-view  v-slot="{ Component, route}"   >
                          <div>
                              <KeepAlive>
                            
                                <component :is="Component" :key="route.path"/>
                              </KeepAlive>

                              </div>
                </router-view>
        
      </main>
    </div>
  </div>
  </div>
</template>

<script setup>
import { useMainStore } from '@/store/index'
import LoaderComponent from '@/components/LoaderComponent.vue'
const store = useMainStore();
import NavBar from "@/components/Shared/NavBar"
import { ApplicationInsights, Exception } from '@microsoft/applicationinsights-web'
import { useRouter } from 'vue-router'
const appInsights = new ApplicationInsights({ config: {
  connectionString: 'InstrumentationKey=cfe85cf5-9dbf-4857-9d9d-19ff9d43002d;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/',
  autoTrackPageVisitTime: true
  
  /* ...Other Configuration Options... */
} });
appInsights.loadAppInsights();
appInsights.trackPageView();

import { ref } from 'vue'
import {
  Dialog,
  DialogPanel,
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Popover,
  PopoverButton,
  PopoverGroup,
  PopoverPanel,
  Tab,
  TabGroup,
  TabList,
  TabPanel,
  TabPanels,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue'
import { Bars3Icon, MagnifyingGlassIcon, ShoppingBagIcon, XMarkIcon } from '@heroicons/vue/24/outline'
import { ChevronDownIcon, PlusIcon } from '@heroicons/vue/20/solid'


const mobileMenuOpen = ref(false)
const mobileFiltersOpen = ref(false)
import {checkIfAuthenticated} from "@/models/checkIfAuthenticated"

checkIfAuthenticated().then(function(data) {
  store.$patch({"isAuthenticated": data})

})
function reddenPage() {
  document.body.style.backgroundColor = 'red';
}

let isHidden = ref(false)
/* eslint-disable */
//@ts-ignore
if(typeof chrome !== "undefined") {

  /* eslint-disable */
  //@ts-ignore
if(chrome.tabs != undefined) {
  console.log("isHidden")
  isHidden.value = true
}
}

var nowAddress = ref("")

</script>

<style  lang="scss">

@import "assets/main.css";


nav {

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

#content {
  margin-top: 60px;
}
@media only screen and (max-width: 600px) {
  #content {
  margin-top: 30px;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>