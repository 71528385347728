
<template>
    <div class="flex space-x-3 ">
  
      <div>
        <div class="text-sm">
          <a href="#" class="font-medium text-gray-900">Admin</a>
        </div>
        <div class="mt-1 text-sm text-gray-700">
          <p>Er zijn nog geen opmerkingen geplaatst. Wat vind jij van dit huis? Is de prijs eerlijk? Zijn er dingen die je opvallen? Hou het netjes en deel geen persoonsgegevens.  </p>
        </div>
        <div class="mt-2 space-x-2 text-sm">
          <span class="font-medium text-gray-500">{{ createdAt }}</span>
          {{ ' ' }}
          {{ ' ' }}
        </div>
      </div>
    </div>
  </template>
  
  <script setup >

  import TimeAgo from 'javascript-time-ago'
  import nl from 'javascript-time-ago/locale/nl';
  TimeAgo.addLocale(nl)
    const timeAgo = new TimeAgo('nl')
    const createdAt = timeAgo.format(new Date("2023-02-02T23:34:13.673876"))


  
  </script>
  
  