<template>
<div>
  <div class="px-4 sm:px-6 lg:px-0">
    <div class="sm:flex sm:items-center">
    </div>
    <div class="flex flex-col" ref="scrollComponent">
      <div class=" ">
        
        <PriceChangeComponent v-for="pricechg in priceChanges" :priceChange="pricechg" v-bind:key="pricechg._id"/>
      </div>

      

    </div>

  </div>
</div>
</template>


<script setup>
import { useMainStore } from '@/store';
import { defineComponent, ref, onMounted, onUnmounted, onDeactivated, onActivated } from 'vue';
import {MainController} from "@/controllers/MainController";
import PriceChangeComponent from "@/components/PriceChangeComponent.vue";


    const mainController = new MainController();
    var searching = false;
    mainController.search();
    const state = useMainStore();
    const priceChanges = state.priceChanges;

 const scrollComponent = ref(null)

 onMounted, onActivated(() => {
  window.addEventListener("scroll", handleScroll)
 })

onDeactivated(() => {
  console.log('remove event listener')
  window.removeEventListener("scroll", handleScroll)
 })

const handleScroll = (e) => {
  let element = scrollComponent.value;

  if(!element) {
    return 
  } 
  if (element.getBoundingClientRect().bottom < window.innerHeight) {
    if(!searching) {
      searching = true;
      mainController.search().then(() => {
        searching = false;
      })
    }
  }
}

</script>
