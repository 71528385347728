// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { Ref, ref, watch } from "vue";
import { useMsal } from "./useMsal";
import { getMsalInstance, loginRequest } from '@/models/AuthConfig';
const msalInstance = getMsalInstance()

export async function checkIfAuthenticated(): Promise<Ref<boolean>> {

        try {
            await msalInstance.acquireTokenSilent(loginRequest);
            return true;
            
        }
        catch {
            return false;
            msalInstance.logoutRedirect({
                onRedirectNavigate: (url) => {
                    // Return false if you would like to stop navigation after local logout
                    return false;
                }
            });

        }


}
