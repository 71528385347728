<template>
    <div class=" pt-8 ">
      <h1 class="text-2xl font-bold tracking-tight text-gray-900 pb-5">  {{title}} </h1>
    </div>

    <div class="grid grid-cols-4 gap-4">

      <div class="col-span-4 lg:col-span-3">
    <Suspense>
    <AddressCommentsComponent :address="title" :topic="topic"/>
  </Suspense>
</div>
  

<div>
  <Suspense>
    
    <div class="bg-white shadow sm:overflow-hidden hidden sm:rounded-lg mb-4 hidden">
              <div class="divide-y divide-gray-200">
                <div class="py-5 px-3">
                  <h2 id="notes-title" class="text-lg font-medium text-gray-900">In de buurt</h2>
                </div>
                <Suspense>
                <CommentFeedComponent :address="title"/>
              </Suspense>
              </div>
            </div>
  </Suspense>
  </div>

  </div>
</template>

<script setup>
import { useRoute } from 'vue-router'

import AddressCommentsComponent from "../components/AddressLevel/AddressCommentsComponent.vue"
import CommentFeedComponent from "../components/Shared/CommentFeed.vue"


const route = useRoute()

const topic = "house"
const title = route.params.address.replaceAll("-"," ")
</script>
