/* eslint-disable */
//@ts-nocheck

import { LogLevel, PublicClientApplication } from "@azure/msal-browser"




// Config object to be passed to Msal on creation
export const msalConfig = {
    auth: {
        clientId: '26961cf7-9d9b-4fa8-be95-e4d6739d4401',
        authority: 'https://huisverklikker.b2clogin.com/huisverklikker.onmicrosoft.com/B2C_1_LOSIUP',
        redirectUri: 'https://mciiflmbhmaandpkabdpgnpljeihdpki.chromiumapp.org', // Must be registered as a SPA redirectURI on your app registration

        knownAuthorities: ["huisverklikker.b2clogin.com"]
        // Must be registered as a SPA redirectURI on your app registration
    },
    cache: {
        cacheLocation: "localStorage",

    },
  system: {
      loggerOptions: {
          loggerCallback: (level: LogLevel, message: string, containsPii: boolean) => {
              if (containsPii) {	
                  return;	
              }
              switch (level) {	
                  case LogLevel.Error:	
                      console.error(message);	
                      return;	
                  case LogLevel.Info:	
                      console.info(message);	
                      return;	
                  case LogLevel.Verbose:	
                      console.debug(message);	
                      return;	
                  case LogLevel.Warning:	
                      console.warn(message);	
                      return;	
                  default:
                      return;
              }
          },
          logLevel: LogLevel.Verbose
      }
  }
};
 


export function getMsalInstance(): PublicClientApplication {

    /* eslint-disable */
    //@ts-ignore
    
    if(typeof chrome !== "undefined") {
        
        if(!chrome.identity) {
            msalConfig.auth.redirectUri = "https://mobiel.huisverklikker.nl"
        }
    }
    return new PublicClientApplication(msalConfig);

}


// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
  scopes: [ 'openid', '26961cf7-9d9b-4fa8-be95-e4d6739d4401'],
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
};



