<template>
  <div class="grid grid-cols-4 gap-x-8">
    <div class="col-span-4 max-w-6xl">
        <div class="border-b border-gray-200 pt-8 ">
          <h1 class="text-4xl font-bold tracking-tight text-gray-900 pb-5">Prijsdalingen <span class="mr-5"></span> <StadListComponent/> </h1>

          
          
          <SearchComponent/>
        </div>

        <MobileFilterComponent/>
    
        <div class="pt-6 pb-14 lg:pb-24 lg:grid lg:grid-cols-3 lg:gap-x-8 xl:grid-cols-4">
          <FilterComponent/>
          <section aria-labelledby="product-heading" class="lg:mt-6  lg:mt-0 col-span-3">
            <h2 id="product-heading" class="sr-only">Products</h2>

              <TableComponent/>
          </section>
        </div>
      </div>

      <!-- <div class="col-span-1">
        <SideTableComponent/>
        </div> -->
    </div>

</template>


<script setup>
import StadListComponent from "@/components/StadListComponent.vue";
import SideTableComponent from "@/components/SideTableComponent.vue";

import { ref, onMounted, onActivated } from 'vue'
import SearchComponent from "@/components/SearchComponent.vue";
import FilterComponent from "@/components/FilterComponent.vue";
import MobileFilterComponent from "@/components/MobileFilterComponent.vue";
import TableComponent from '@/components/TableComponent.vue'; // @ is an alias to /src
import { useHead } from 'unhead'
import { useRoute } from 'vue-router'
const route = useRoute();
useHead({
  title: 'Vind huizen die in prijs dalen',  
  meta: [
    {name: 'description', content: 'Met Huisverklikker hou jij de huizenmarkt in de gaten. Wij checken voor jou welke huizen er in prijs dalen, jij profiteert! 😀'}
  ]
})
console.log('loaded')

if(route.params.city) {
  const citystr = route.params.city[0].toUpperCase() + route.params.city.slice(1);
  useHead({
  title: 'Prijsdalingen van huizen in ' + citystr,  
  meta: [
    {name: 'description', content: 'Met Huisverklikker hou jij de huizenmarkt in de gaten. Wij checken voor jou welke huizen er in prijs dalen, jij profiteert! 😀'}
  ]
})
}

</script>