
<template>
  <div class="flex space-x-3 ">

    <div>
      <div class="text-sm">
        <a href="#" class="font-medium text-gray-900">{{ comment.username }}</a>
      </div>
      <div class="mt-1 text-sm text-gray-700">
        <p>{{ comment.content }}</p>
      </div>
      <div class="mt-2 space-x-2 text-sm">
        <span class="font-medium text-gray-500 pr-0 mr-0">{{ createdAt }}</span><span class="font-medium text-gray-500 cursor-pointer" style="margin:5px !important;" v-on:click="reportComment">| Melden </span>
    
      </div>
    </div>
  </div>
<NotificationComponent v-if="isReported"/>
</template>

<script>
import NotificationComponent from "./NotificationComponent.vue"
import {ref} from 'vue';
    import TimeAgo from 'javascript-time-ago'
  import nl from 'javascript-time-ago/locale/nl';
  import CommentController from "@/controllers/CommentController";

export default {
  props: {
    comment: Object
  },

  components: {
    NotificationComponent
  },

  setup(props) {
  const isReported = ref(false);
  TimeAgo.addLocale(nl)
    const timeAgo = new TimeAgo('nl')
    const createdAt = timeAgo.format(new Date(props.comment.createdAt))
    return {createdAt, isReported}
  },
  methods: {
    async reportComment() {
      const cc = new CommentController();
      await cc.reportComment(this.comment._id);
      this.isReported = true;
    }
  }
}

</script>

