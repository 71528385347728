import { createPinia, storeToRefs } from 'pinia'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { vue3Debounce } from 'vue-debounce'
import { msalPlugin } from "@/models/Msal";
import { getMsalInstance } from "@/models/AuthConfig";
import { CustomNavigationClient } from './router/NavigationClient';
import { AuthenticationResult, EventType } from '@azure/msal-browser';
import { createHead } from "@vueuse/head"
import { useMainStore } from '@/store/index'

const head = createHead()
const pinia = createPinia()
const msalInstance = getMsalInstance()


const navigationClient = new CustomNavigationClient(router);
msalInstance.setNavigationClient(navigationClient);

const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event) => {
  console.log('got event', event)
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;

    const store = useMainStore();
    store.$patch({"isAuthenticated": true})
    console.log("isauth now true ")
    msalInstance.setActiveAccount(account);
  }
});


/* eslint-disable */

async function poop() {

  //@ts-ignore
  if(!chrome.tabs) {
    return
  }
  //@ts-ignore
  const [tab] = await chrome.tabs.query({ active: true, currentWindow: true });
  let result;
  try {
    //@ts-ignore
    [{ result }] = await chrome.scripting.executeScript({
      target: { tabId: tab.id },
      func: () => document.documentElement.innerText,
    });
  } catch (e) {
    console.log(e);
  }
  // process the result
  const matches = result.match(/([0-9]+\s{0,1}[abcdefgIi]{0,1})[\)]*\s+([0-9]{4})\s+?([A-z]{2})/)
  
  if(matches) {
    if (matches.length > 2) {
      const address = { "postalcode": matches[2] + " " + matches[3], "number": matches[1] }
      const navigationResult = await router.push({path: "/comments/" + address["number"] + " " +  address["postalcode"], force: true, replace: true})
    }  
    else {
      const navigationResult = await router.push({path: "/noneView/", force: true, replace: true})
  
    }
  }
  

  else {
    const navigationResult = await router.push({path: "/noneView/", force: true, replace: true})

  }
}

async function init() {
    const app = createApp(App)
      .use(pinia)
      .use(msalPlugin, msalInstance)
      .use(router)    
      .use(head)

      .directive('debounce', vue3Debounce({ lock: true }))
      router.isReady().then(() => {
        // Waiting for the router to be ready prevents race conditions when returning from a loginRedirect or acquireTokenRedirect
        app.mount('#app');
      });
      
  }


  init().then(() => {
    poop().then(() => {
      console.log("skieepdad")
    })})
  
  
  
  