<template>
  <div>
    <div class="relative mt-1  items-center">
  <div>
    <label for="email" class="block text-sm font-medium text-gray-700">Postcode</label>
    <div class="grid grid-cols-5">
        <div class="col-span-3 ">
            <input type="number" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-xs" 
                min="0" v-model.number="postcode" @keyup.enter="search()" @input="setPostCode"
             />
        </div>

        <div class="col-span-2 ">
            <PostalCodeRangeSelectComponent/>
        </div>

    </div>
    <p class="mt-2 text-xs text-gray-500" id="email-description">De eerste 4 cijfers van de postcode waarin je wil zoeken.</p>
    <p v-if="invalid" class="mt-2 text-xs text-red-600" id="email-error">De postcode moet bestaan uit 4 cijfers.</p>

  </div>

    </div>
  </div>
</template>

<script setup>
import { defineComponent, computed, ref} from 'vue'
import { useMainStore } from '@/store/index'
import {MainController} from "@/controllers/MainController"
import PostalCodeRangeSelectComponent from "@/components/PostalCodeRangeSelectComponent.vue";

const mainController = new MainController();
const state = useMainStore();
var invalid = ref(false)
var postcode = ref()

function isFull() {
    if(postcode.value > 999) {
        return true
    }

    else{
        return false;
    }
}

function search() {
    mainController.clear();
    mainController.search();
}

function setPostCode(e) {

   
    if(postcode.value < 9999 & postcode.value > 999) {
        state.filterOptions.postalCodeString = postcode.value
        state.filterOptions.searchString = ""
        invalid.value=false;
    }
    else if(postcode.value > 9999){
        state.filterOptions.postalCodeString = undefined

        invalid.value=true;
        postcode.value = Math.floor(postcode.value / 10)
    }
}


</script>

<style>

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>
