import { FilterOptions } from "@/models/FilterOptions";
import router from "@/router";
import { useMainStore } from "@/store";
import { RootState } from "@/store";
import axios from "axios";


export class MainController {
    
    filterOptions: FilterOptions = new FilterOptions;

    async clear() {
        const store = useMainStore();
        store.filterOptions.pageNumber = 0;
        store.priceChanges.splice(0, store.priceChanges.length)
    }

    async search(): Promise<void> {
        this.filterOptions = useMainStore().filterOptions
        
        const store = useMainStore();
        store.$patch({"isLoading": true})

    
        const results = await axios.post(process.env.VUE_APP_APIURL + "/search", this.filterOptions);
        const data = results.data as Array<object>

        store.$state.priceChanges.push(...data)

        store.$state.filterOptions.pageNumber += 1;
        store.$patch({"isLoading": false})

    }

 

    
}
