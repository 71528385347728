<template>
    
    <span v-for="city in cities" v-bind:key="city.stad">
    <router-link :to="city.link">
    <span class="inline-flex items-center mr-3 gap-x-1.5 rounded-full bg-indigo-100 px-1.5 py-0.5 text-sm font-medium text-gray-600">
      <svg class="h-1.5 w-1.5 fill-gray-400" viewBox="0 0 6 6" aria-hidden="true">
        <circle cx="3" cy="3" r="3" />
      </svg>
      {{city.stad}}
    </span>
    
  </router-link>
</span>
  </template>
  
<script setup>

  const cities = [
    {"stad": "Amsterdam", "link": "/stad/Amsterdam"},
    {"stad": "Rotterdam", "link": "/stad/Rotterdam"},
    {"stad": "Utrecht", "link": "/stad/Utrecht"},
    {"stad": "Den Haag", "link": "/stad/Den%20Haag"},
    {"stad": "Eindhoven", "link": "/stad/Eindhoven"},
    {"stad": "Groningen", "link": "/stad/Groningen"},
    {"stad": "Tilburg", "link": "/stad/Tilburg"},
    {"stad": "Almere", "link": "/stad/Almere"},
    {"stad": "Breda", "link": "/stad/Breda"},
    {"stad": "Nijmegen", "link": "/stad/Nijmegen"},
  ];
  
  
</script>
  