<template>
        <aside>
            <h2 class="sr-only">Filters</h2>

            <button type="button" class="inline-flex items-center lg:hidden" @click="openFilters">
              <span class="text-sm font-medium text-gray-700 p-1">Filters</span>
              <PlusIcon class="ml-1 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
            </button>


            <div class="hidden lg:block">
              <div class="space-y-10 divide-y divide-gray-200">
                <div>

                    <SortComponent/>
                    <div class="mt-6"/>
                    <PostalCodeComponent/>
                    <div class="mt-6"/>
                                              <SearchButton/>

                </div>
              </div>
            </div>
          </aside>
</template>

<script setup>
import { ref } from 'vue'
import { Bars3Icon, MagnifyingGlassIcon, ShoppingBagIcon, XMarkIcon } from '@heroicons/vue/24/outline'
import { ChevronDownIcon, FunnelIcon, MinusIcon, PlusIcon, Squares2X2Icon } from '@heroicons/vue/20/solid'
import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/vue/20/solid'
import { useMainStore } from '@/store/index'
import SortComponent from "@/components/SortComponent.vue"; 
import PostalCodeComponent from "@/components/PostalCodeComponent.vue"; 
import SearchButton from "@/components/SearchButton.vue"; 

const store = useMainStore();
const selected = store.filterOptions.selectedSortOption


function select(){store.filterOptions.selectedSortOption = selected.value}

function openFilters() {

  store.$patch({mobileFiltersOpen: true})
 
}
</script>
