<template>
    
  <button v-on:click="search"
  type="button" 
  class="inline-flex items-center rounded border border-transparent bg-indigo-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
  Zoeken</button>


</template>

<script setup>
import {MainController} from "@/controllers/MainController"
import { useMainStore } from '@/store/index'
import { storeToRefs } from 'pinia'

const state = useMainStore();

const mainController = new MainController()
function search() {
    mainController.clear();
    mainController.search();
    state.mobileFiltersOpen = false;

}


</script>