<template>
    <section aria-labelledby="notes-title">
      
            <div class="bg-white shadow sm:overflow-hidden sm:rounded-lg mb-4">
              <div class="divide-y divide-gray-200">
                <div class="py-5 px-3">
                  <h2 id="notes-title" class="text-lg font-medium text-gray-900">Opmerkingen</h2>
                </div>
                <CommentList :comments="comments"/>
                <Suspense>
                <CommentInputField :topic="props.topic" :address="props.address" :comments="comments" @newComment="addComment"/>
              </Suspense>
              </div>
            </div>
          </section>
</template>

<script setup>
import CommentInputField from "../Shared/CommentInputField.vue"
import CommentList from "../Shared/CommentList.vue"
import { defineProps, ref} from "vue";
import CommentController from "@/controllers/CommentController";

const props = defineProps({
      address: String,
      topic: String
})

const commentController = new CommentController();
var comments = ref([])
comments.value = await commentController.getComments(props.address, props.topic)


function addComment(comment) {
  console.log("got comment", comment)
  comments.value.push(comment)
}

</script>
