import { createRouter, createWebHashHistory,  RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AddressView from '../views/AddressView.vue'
import NoneView from '../views/NoneView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/stad/:city',
    name: 'homecity',
    component: HomeView
  },
  { path: '/comments/:address', component: AddressView, name:"comments"},
  { path: '/noneView', component: NoneView, name:"noneView"}

]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})


export default router
