import { PublicClientApplication } from '@azure/msal-browser';
import axios from 'axios';
import { getMsalInstance, loginRequest } from '@/models/AuthConfig';
import { IComment } from '@/library/IComment';
import { useMainStore } from '@/store';
export default class CommentController {

    clientApp: PublicClientApplication

    constructor() {
        this.clientApp = getMsalInstance();
        axios.defaults.baseURL = process.env.VUE_APP_API_URL;
    }
 
    async makeComment(comment: object): Promise<IComment> {
        let authRequest = undefined;

        authRequest = await this.clientApp.acquireTokenSilent(loginRequest);

    
        const apiResult = await axios.post(process.env.VUE_APP_APIURL + "/comments", 
            comment, {headers: {"Authorization": "Bearer " + authRequest.accessToken}})

        
        console.log("comment is", apiResult.data)
        return apiResult.data;
    }   

    
    async reportComment(commentId: string): Promise<IComment> {
        let authRequest = undefined;

        authRequest = await this.clientApp.acquireTokenSilent(loginRequest);
 
        const apiResult = await axios.post(process.env.VUE_APP_APIURL + "/comments/report/" + commentId, {}, {headers: {"Authorization": "Bearer " + authRequest.accessToken}})
        
        console.log("comment is", apiResult.data)
        return apiResult.data;
    }   

    async getComments(address: string, topic:string, maxDistanceInKm: number): Promise<IComment[]> {
        const store = useMainStore();

        store.$patch({"isLoading": true})
    
      
        const apiResult = await axios.get<IComment[]>(process.env.VUE_APP_APIURL + "/comments", 
        {params: { address: address, topic: topic, maxDistanceInKm: maxDistanceInKm}})

        const data = apiResult.data as Array<IComment>

          
        store.$patch({"isLoading": false})
        return data;
    }   

    async getFeedComments(address: string): Promise<IComment[]> {
        const store = useMainStore();

        store.$patch({"isLoading": true})
    
      
        const apiResult = await axios.get<IComment[]>(process.env.VUE_APP_APIURL + "/feedComments", 
        {params: { address: address, topic: "house"}})

        const data = apiResult.data as Array<IComment>

          
        store.$patch({"isLoading": false})
        return data;
    }   



}
