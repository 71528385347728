<template >
<span v-on:click="login">
       Login
</span>
</template>


<script setup>
/* eslint-disable */
import { useMsal } from '@/models/useMsal';
import { getMsalInstance, loginRequest } from '@/models/AuthConfig';

const msalInstance = getMsalInstance()

  
async function getLoginUrl(request, reject) {
    return new Promise((resolve) => {
        msalInstance.loginRedirect({
            ...request, scopes: [ 'openid', '26961cf7-9d9b-4fa8-be95-e4d6739d4401'],
            onRedirectNavigate: (url) => {
                resolve(url);
                router.push("/");
                return false;
            }
        }).catch(reject);
    });
}

async function signIn() {
    const url = await getLoginUrl();
    return new Promise((resolve, reject) => {
        chrome.identity.launchWebAuthFlow({
            interactive: true,
            url
        }, (responseUrl) => {
            // Response urls includes a hash (login, acquire token calls)
            if (responseUrl.includes("#")) {
                msalInstance.handleRedirectPromise(`#${responseUrl.split("#")[1]}`)
                    .then(resolve)
                    .catch(reject)
            } else {
            
                console.log('something went')
                // Logout calls
                resolve();
            }
        })
    })
}



 async function login() {

/* eslint-disable */
//@ts-ignore
if(typeof chrome !== "undefined") {
  if(chrome.identity) {

    await signIn();



  }
  else {
    msalInstance.loginPopup({scopes: [ 'openid', '26961cf7-9d9b-4fa8-be95-e4d6739d4401']})
    console.log('login')

  }

}
 }


</script>
