<template>
  <Disclosure as="nav" class="bg-gray-800" >
    <div class="mx-auto max-w-8xl px-4 sm:px-6 lg:px-8" >
      
    </div>

    <DisclosurePanel class="sm:hidden">
      <div class="space-y-1 px-2 pt-2 pb-3">
        <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" -->
        <router-link to='/' >
        <DisclosureButton as="a" href="#" class="block rounded-md  px-3 py-2 text-base font-medium text-white">Dashboard</DisclosureButton>
      </router-link>
      <DisclosureButton as="a" v-if="store.state.isAuthenticated == true" v-on:click="logoutPopup" href="#" class="block rounded-md  px-3 py-2 text-base font-medium text-white">Logout</DisclosureButton>
      </div>
    
    </DisclosurePanel>
  </Disclosure>
</template>

<script setup>
import LoginButton from "@/components/User/LoginButton"
import LogoutButton from "@/components/User/LogoutButton"
import { useMsal } from "@/models/useMsal"
import { useMainStore } from '@/store/index'

const store = useMainStore();


    const { instance } = useMsal();


import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/vue/24/outline'

const logoutPopup = () => {
  instance.logoutPopup({
    mainWindowRedirectUri: "/"
  });
}
const logoutRedirect = () => {
  instance.logoutRedirect();
}

</script>
