import { IFilterOptions } from "./IFilterOptions";

export class FilterOptions implements IFilterOptions {
    sortOptions = [
        {"displayString": "Nieuwste eerst", "value": "-date"}, 
        {"displayString": "Absoluut hoogste prijsverschil eerst", "value": "pricediff"},
        {"displayString": "Relatief hoogste prijsverschil eerst", "value": "priceChangePercentage"}
    ]

    selectedSortOption =  {"displayString": "Nieuwste eerst", "value": "-date"}

    searchString = ""

    pageNumber = 0

    postalCodeString = 0

    rangeOptions = [
        {"displayString": "5 km", "value": "5"}, 
        {"displayString": "10 km", "value": "10"},
        {"displayString": "25 km", "value": "25"},
        {"displayString": "50 km", "value": "50"}

    ]

    selectedRangeOption =  {"displayString": "5 km", "value": "5"}

}
